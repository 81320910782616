:global {
    .iti {
        width: 100%;
        * {
            box-sizing: content-box;
        }
        input {
            width: calc(100% - 96px);
        }
    }
}
